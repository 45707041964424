import React, { useState } from 'react'
import { useInlineMemoizedContentGetter, useMemoizedContentGetter } from 'utils/contentful'
import { Typography, MediaImageStandard } from 'components/Contentful'
import { CardMedia as AscendCardMedia, Box, Grid } from '@achieve/ascend'
import Section from 'components/Section'
import SectionContainer from 'components/Section/SectionContainer'
import { useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'
import { Eyebrow } from 'components/Eyebrow'
import styles from './DebtConsolidationSolutions.module.scss'
import { VideoPlayer } from 'components/VideoPlayer'
import { SectionAccordion } from 'components/SectionAccordion.js'
import { StickyTabs } from 'components/StickyTabs'

function DebtConsolidationSolutions({ content }) {
  // Initialize with the first section open
  const [selectedSection, setSelectedSection] = useState(0)

  // eslint-disable-next-line no-unused-vars
  const {
    jsonContent: {
      prosBoxTitle,
      consBoxTitle,
      consBoxTitleColor,
      prosBoxTitleColor,
      boxBorderNone,
      boxMargin,
      stickyTabsLabels = [],
      collapsible,
      style,
    } = {},
  } = useInlineMemoizedContentGetter(content?.fields?.additionalConfiguration, ['jsonContent'])

  const handleAccordionChange = (index) => {
    // Toggle the selected section. If the section is already open, close it, otherwise open it.
    setSelectedSection(selectedSection === index ? null : index)
  }

  // Utility function to render the accordion content based on the section type
  const renderSectionContent = (sectionType, grid, idx) => {
    const notLast = !collapsible && idx != sectionElements.length - 1
    switch (sectionType) {
      case 'textSection':
        return renderTextSection(grid, notLast)
      case 'videoSection':
        return renderVideoSection(grid, notLast)
      case 'cardSection':
        return renderCardSection(grid, notLast)
      case 'linkedSection':
        return renderLinkedSection(grid, notLast)
      case 'linkedIconSection':
        return renderLinkedIconSection(grid, notLast)
      case 'boxSection':
        return renderBoxSection(grid, notLast)
      default:
        return null
    }
  }
  const sectionElements = content?.fields?.sectionContents?.filter((section) => {
    return [
      'textSection',
      'videoSection',
      'cardSection',
      'linkedSection',
      'linkedIconSection',
      'boxSection',
    ].includes(section?.fields?.fieldName)
  })

  const { eyebrow } = useMemoizedContentGetter(content, ['eyebrow'])

  let isMobile = useViewportSmallerThan(BREAKPOINTS.lg)

  const HairlineDivider = () => {
    return <hr className={styles['divider']} />
  }

  const colorMap = {
    grey: 'grey',
  }

  const TableMaker = ({ box, pros, cons }) => {
    return (
      <div className={styles['table-container']}>
        <div className={styles['table-wrapper']}>
          {box?.gridItemsCollection?.items?.map((pro, id) => {
            if (id === 0) {
              return (
                <div
                  key={id}
                  className={
                    pros
                      ? boxBorderNone
                        ? styles['pro-head-borderless']
                        : styles['pro-head']
                      : boxMargin
                      ? boxBorderNone
                        ? styles['pro-head-borderless']
                        : styles['pro-head']
                      : boxBorderNone
                      ? styles['con-head-borderless']
                      : styles['con-head']
                  }
                >
                  <AscendCardMedia
                    data-testid="ascend-card-media"
                    className={styles['table-image']}
                  >
                    <MediaImageStandard
                      data-testid="card-media-image"
                      layout="fixed"
                      width={30}
                      height={30}
                      content={box.gridItemsCollection.items[0].mediaContent}
                    />
                  </AscendCardMedia>
                  <Typography
                    className={
                      styles[
                        pros
                          ? colorMap[prosBoxTitleColor] ?? 'black'
                          : colorMap[consBoxTitleColor] ?? 'black'
                      ]
                    }
                    fontWeight="medium"
                    variant="bodySm"
                    content={pros ? prosBoxTitle ?? 'Pros' : consBoxTitle ?? 'Cons'}
                  />
                </div>
              )
            } else if (id !== 0) {
              return (
                <div
                  key={id}
                  className={
                    (box.gridItemsCollection.items.length - 1 === id && !isMobile) ||
                    (box.gridItemsCollection.items.length - 1 === id && !pros) ||
                    (box.gridItemsCollection.items.length - 1 === id && !cons) ||
                    (box.gridItemsCollection.items.length - 1 === id && boxMargin)
                      ? boxBorderNone
                        ? styles['table-last-row-borderless']
                        : styles['table-last-row']
                      : boxBorderNone
                      ? styles['table-row-borderless']
                      : styles['table-row']
                  }
                >
                  <Typography fontWeight="medium" variant="bodySm" content={pro?.textContent} />
                </div>
              )
            }
          })}
        </div>
      </div>
    )
  }

  const extractProperty = (grid, property) => {
    return grid?.filter((element) => {
      return element?.fieldName == property
    })[0]
  }

  const renderSection = (element, idx) => {
    const sectionType = element?.fields?.fieldName
    const grid = element?.fields?.gridItemsCollection?.items
    const title = extractProperty(grid, 'title')?.textContent?.json?.content?.[0]?.content[0]?.value
    const sectionContent = renderSectionContent(sectionType, grid, idx)

    return {
      element: collapsible ? (
        <SectionAccordion
          key={idx}
          title={title}
          section={sectionContent}
          sectionNumber={idx}
          selected={selectedSection === idx}
          onChangeExpanded={() => handleAccordionChange(idx)}
          index={idx}
        />
      ) : (
        sectionContent
      ),
      title: title,
    }
  }

  const SectionTitle = ({ title }) => {
    return (
      <Typography
        content={title}
        fontWeight="medium"
        variant={isMobile ? 'headingMd' : 'displayMd'}
        className={styles['text-title']}
      />
    )
  }

  const renderVideoSection = (grid) => {
    const videoTitle = extractProperty(grid, 'title')
    const videoPlayer = extractProperty(grid, 'videoPlayer')
    if (!videoTitle) return null
    return (
      <SectionContainer className={styles['video-container']} narrow={!collapsible}>
        {!collapsible && videoTitle?.textContent && <SectionTitle title={videoTitle.textContent} />}
        <div className={styles['video-player']}>
          <VideoPlayer content={videoPlayer} aspectRatio={16 / 9} extraPadding={120} />
        </div>
      </SectionContainer>
    )
  }

  const renderTextSection = (grid, notLast) => {
    const title = extractProperty(grid, 'title')
    const text = extractProperty(grid, 'text')
    if (!title) return null
    return (
      <SectionContainer narrow={!collapsible}>
        {!collapsible && title?.textContent && <SectionTitle title={title.textContent} />}
        <Typography
          content={text?.textContent}
          variant={'bodySm'}
          className={styles['first-text-margin']}
        />
        {notLast && <HairlineDivider />}
      </SectionContainer>
    )
  }

  const renderCardSection = (grid, notLast) => {
    const title = extractProperty(grid, 'title')
    const text = extractProperty(grid, 'text')
    const gridContent = extractProperty(grid, 'grid')
    if (!text || !title || !gridContent) return null
    return (
      <SectionContainer narrow={!collapsible}>
        {!collapsible && title?.textContent && <SectionTitle title={title.textContent} />}
        <Typography content={text?.textContent} variant={'bodySm'} />
        {gridContent?.gridItemsCollection?.items?.map((card, id) => {
          return (
            <React.Fragment key={id}>
              <Box className={styles['paper-card']}>
                {card?.cardBadgeImage && (
                  <AscendCardMedia
                    data-testid="ascend-card-media"
                    className={styles['table-second-image']}
                  >
                    <MediaImageStandard
                      data-testid="card-media-image"
                      layout="fixed"
                      width={42}
                      height={42}
                      content={card.cardBadgeImage}
                    />
                  </AscendCardMedia>
                )}

                <Typography
                  data-testid="card-subtitle"
                  variant={'bodySm'}
                  component="p"
                  content={card.cardSubtitleText}
                />
              </Box>
            </React.Fragment>
          )
        })}
        {notLast && <HairlineDivider />}
      </SectionContainer>
    )
  }

  const renderLinkedSection = (grid, notLast) => {
    const title = extractProperty(grid, 'title')
    const text = extractProperty(grid, 'text')
    const gridContent = extractProperty(grid, 'grid')
    if (!text || !title || !gridContent) return null
    return (
      <SectionContainer narrow={!collapsible}>
        {!collapsible && title?.textContent && <SectionTitle title={title.textContent} />}
        <Typography
          content={text?.textContent}
          variant={'bodySm'}
          className={styles['text-title']}
        />
        <ul className={styles['ul-padding']}>
          {gridContent?.gridItemsCollection?.items?.map((card, id) => {
            let intermediateObject = false

            if (card?.cardTitleText && card?.cardSubtitleText) {
              let text = [
                card?.cardTitleText?.json?.content[0].content[0],
                {
                  nodeType: 'text',
                  value: ' ',
                  marks: [{ type: 'bold' }],
                  data: {},
                },
                card?.cardSubtitleText?.json?.content[0].content[0],
              ]
              //prevents creating just a reference.
              intermediateObject = JSON.parse(JSON.stringify(card?.cardTitleText))
              intermediateObject.json.content[0].content = text
            }

            return (
              <li key={id} className={styles['list']}>
                <Box style={{ display: 'flex' }}>
                  <Box className={styles['list-item']}>
                    {card?.cardBadgeImage && (
                      <AscendCardMedia
                        data-testid="ascend-card-media"
                        className={
                          styles[
                            id === 0
                              ? 'timeline-start'
                              : id === gridContent?.gridItemsCollection?.items.length - 1
                              ? 'timeline-end'
                              : 'timeline'
                          ]
                        }
                      >
                        <MediaImageStandard
                          data-testid="card-media-image"
                          layout="fixed"
                          width={40}
                          height={40}
                          content={card.cardBadgeImage}
                        />
                      </AscendCardMedia>
                    )}
                  </Box>
                  <Box className={styles['list-text-container']}>
                    <Typography
                      data-testid="card-subtitle"
                      variant={'bodySm'}
                      component="p"
                      content={
                        isMobile
                          ? card?.cardTitleText
                          : intermediateObject
                          ? intermediateObject
                          : card?.cardTitleText
                      }
                    />
                    <Typography content={' '} />
                    {isMobile && (
                      <Typography
                        data-testid="card-subtitle"
                        variant={'bodySm'}
                        component="p"
                        content={card?.cardSubtitleText}
                      />
                    )}
                  </Box>
                </Box>
              </li>
            )
          })}
        </ul>
        {notLast && <HairlineDivider />}
      </SectionContainer>
    )
  }

  const renderLinkedIconSection = (grid, notLast) => {
    const title = extractProperty(grid, 'title')
    const text = extractProperty(grid, 'text')
    const gridContent = extractProperty(grid, 'grid')
    if (!text || !title || !gridContent) return null
    return (
      <SectionContainer narrow={!collapsible}>
        {!collapsible && title?.textContent && <SectionTitle title={title.textContent} />}
        <Typography
          content={text?.textContent}
          variant={'bodySm'}
          className={styles['first-text-margin']}
        />
        {gridContent?.gridItemsCollection?.items?.map((card, id) => {
          return (
            <React.Fragment key={id}>
              <Box style={{ display: 'flex' }}>
                <Box className={styles['list-item']}>
                  {card?.cardBadgeImage && (
                    <AscendCardMedia
                      data-testid="ascend-card-media"
                      className={
                        styles[
                          id === 0
                            ? 'timeline-start'
                            : id === gridContent?.gridItemsCollection?.items?.length - 1
                            ? 'timeline-end'
                            : 'timeline'
                        ]
                      }
                    >
                      <MediaImageStandard
                        data-testid="card-media-image"
                        layout="fixed"
                        width={40}
                        height={40}
                        content={card.cardBadgeImage}
                      />
                    </AscendCardMedia>
                  )}
                </Box>
                <Box className={styles['list-alignment']}>
                  {card?.cardImage && (
                    <AscendCardMedia data-testid="ascend-card-media">
                      <MediaImageStandard
                        data-testid="card-media-image"
                        layout="fixed"
                        width={40}
                        height={40}
                        content={card.cardImage}
                      />
                    </AscendCardMedia>
                  )}
                  <Typography
                    data-testid="card-subtitle"
                    variant={'bodySm'}
                    component="p"
                    fontWeight="medium"
                    content={card?.cardTitleText}
                  />
                  <Typography
                    data-testid="card-subtitle"
                    variant={'bodySm'}
                    component="p"
                    content={card?.cardSubtitleText}
                  />
                </Box>
              </Box>
            </React.Fragment>
          )
        })}
        {notLast && <HairlineDivider />}
      </SectionContainer>
    )
  }

  const renderBoxSection = (grid, notLast) => {
    const title = extractProperty(grid, 'title')
    const text = extractProperty(grid, 'text')
    const prosBox = extractProperty(grid, 'prosBox')
    const consBox = extractProperty(grid, 'consBox')
    if (!title || !(!prosBox && !consBox))
      //Returns false if no prosBox and no consBox or no title
      return (
        <SectionContainer narrow={!collapsible}>
          {!collapsible && title?.textContent && <SectionTitle title={title.textContent} />}
          <Typography content={text?.textContent} variant={'bodySm'} />
          <Grid container className={styles['table-grid']}>
            {prosBox && <TableMaker box={prosBox} pros={true} cons={!!consBox} />}
            {consBox && <TableMaker box={consBox} />}
          </Grid>
          {notLast && <HairlineDivider />}
        </SectionContainer>
      )
  }

  const sections = sectionElements.map(renderSection)

  // TODO: Implement sticky tabs for collapsible SectionAccordion component
  let stickyTabsContent = []
  let labelCounter = 0
  for (let i = 0; i < sections.length; i++) {
    if (sections[i].title) {
      stickyTabsContent.push({
        tabLabel:
          stickyTabsLabels[labelCounter] ??
          sections[i].title?.textContent?.content[0]?.content[0]?.value,
        tabItem: sections[i].element,
      })
      labelCounter++
    }
  }

  return (
    <Section className={styles['section']} sectionStyles={style}>
      <Grid alignItems="center" columnSpacing={{ xs: 1, lg: 3 }} container justifyContent="center">
        <Grid item xs={collapsible ? 11 : 12}>
          {eyebrow?.textContent && <Eyebrow content={eyebrow.textContent} component="h2" black />}
        </Grid>
        <Grid item xs={collapsible ? 11 : 12} lg={collapsible ? 9 : 12}>
          {(collapsible || !isMobile) && sections.map((section) => section.element)}
        </Grid>

        {!collapsible && isMobile && (
          <StickyTabs
            className={styles['sticky-subnav']}
            isMobile={true}
            stickyTabsContent={stickyTabsContent}
          />
        )}
      </Grid>
    </Section>
  )
}

export default DebtConsolidationSolutions
